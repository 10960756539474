import React, { Fragment } from 'react';
import { SectionTitle } from '../../../../components';
import { Row, Col } from 'react-bootstrap';
import styles from './index.module.css';
import { roundNumber } from '../../../../utils';

const Results = ({ 
    results
}) => {
    const isWaling = Array.isArray(results.waling.designCeo) && results.waling.designCeo.length > 0;
    return (
        <Fragment>
            <div className={styles.wrapper}>
                <div className={styles.flex}>
                    <SectionTitle title="Wyniki" />
                    <span
                        className={`${styles.actualResults} ${
                            results.isResultsActual
                                ? ''
                                : styles.wrongResults
                        }`}
                        title={
                            results.isResultsActual
                                ? 'Obliczenia są aktualne'
                                : 'Obliczenia są nieaktualne'
                        }>
                    </span>
                </div>
                {
                    results.isResultValid 
                    ?
                    <Fragment>
                        {
                            results.checkElements 
                            ?
                            <Row>
                                <Col xs={12}>
                                    <SectionTitle title="Warunki wytrzymałości" />
                                    {
                                        results.type === 1
                                        ?
                                        <Fragment>
                                            <p>Kleszcz stalowy</p>
                                            <p>
                                                M<sub>Ed,waling</sub> = {results.forces.Med} kNm
                                            </p>
                                            <p>
                                                W<sub>x,req</sub> = {results.waling.ceoWx} cm<sup>3</sup>
                                            </p>
                                            <p>
                                                W<sub>x,req</sub> &le; W<sub>x,ceo</sub>

                                                {'=>'} <b>{results.waling.ceoWx} cm<sup>3</sup> &le; {results.waling.designCeo[1].wx} cm<sup>3</sup></b> (
                                                    <span style={{color: results.waling.ceoWx/results.waling.designCeo[1].wx <=  1 ? 'green' : 'red'}}>{roundNumber(100*results.waling.ceoWx/results.waling.designCeo[1].wx,2)}%</span>
                                                )
                                            </p>
                                            <p>
                                                <b>
                                                    <span style={{color: results.waling.ceoWx/results.waling.designCeo[1].wx <=  1 ? 'green' : 'red'}}>
                                                        {
                                                            results.waling.ceoWx/results.waling.designCeo[1].wx <=  1 ? 'Kleszcz zaprojektowany poprawnie' : 'Kleszcz zaprojektowany błędnie'
                                                        }
                                                    </span>                                                    
                                                </b>
                                            </p>
                                            <p>Nośność płyty oporowej</p>
                                            <p>
                                                Masa płyty - {roundNumber(results.plate.m*0.001,3)} kg
                                            </p>
                                            <p>
                                                F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                                {'['}
                                                    -(b<sub>a</sub> - d') + 
                                                    <span style={{ whiteSpace: "nowrap" }}>
                                                        &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;(b<sub>a</sub> - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                                    </span>
                                                {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Fmvrd} kN</b> (
                                                    <span style={{color: results.forces.Fed/results.forces.Fmvrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.Fmvrd,2)}%</span>
                                                )
                                            </p>

                                            <p>
                                                F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;2&nbsp;</span>
                                                </span>
                                                </sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                
                                                {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN</b> (<span style={{color: results.forces.Fed/results.forces.FrdL1 <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.FrdL1,2)}%</span>)
                                            </p>

                                            <p>
                                                F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                </span>
                                                </sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                
                                                
                                                {'=>'} <b>{results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN</b> (
                                                    <span style={{color: results.forces.FrdL1/results.forces.FrdL2 <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.FrdL1/results.forces.FrdL2,2)}%</span>  
                                                )
                                            </p>

                                            <p>
                                                <b>
                                                    <span style={{color: 
                                                        results.forces.FrdL1/results.forces.FrdL2 <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.Fed/results.forces.Fmvrd <= 1
                                                        ? 'green' 
                                                        : 'red'
                                                    }}>
                                                        {
                                                            results.forces.FrdL1/results.forces.FrdL2 <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.Fed/results.forces.Fmvrd <= 1 
                                                            ? 'Płyta oporowa zaprojektowana poprawnie' 
                                                            : 'Płyta oporowa zaprojektowana błędnie'
                                                        }
                                                    </span>
                                                </b>
                                            </p>

                                            <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>
                                            <p>F<sub>Ed,web</sub> &le; 0.5R<sub>c,Rd</sub> {'=>'} <b>{results.forces.Fed*0.5} kN &le; {0.5*results.forces.Rcrd} kN</b> {'=>'} {0.5*results.forces.Fed > 0.5*results.forces.Rcrd ? 'Potrzebna dalsza weryfikacja' : 'Dalsza weryfikacja nie jest konieczna'}</p>
                                            {
                                                0.5*results.forces.Fed <= 0.5*results.forces.Rcrd &&
                                                <p>
                                                    <b>
                                                        <span style={{color:'green'}}>Ścianka szczelna zaprojektowana poprawnie</span>
                                                    </b>
                                                </p>
                                            }
                                        
                                            {
                                                0.5*results.forces.Fed > 0.5*results.forces.Rcrd &&
                                                <Fragment>
                                                    <p>
                                                        <sup>F<sub>Ed</sub></sup>&frasl;<sub>R<sub>c,Rd</sub></sub> + 0.5<sup>M<sub>Ed</sub></sup>&frasl;<sub>M<sub>c,Rd</sub></sub> &le; 1 {'=>'} <b>{results.forces.conditionVal} &le; 1 </b> (<span style={{color: results.forces.conditionVal <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.conditionVal,2)}%</span>)
                                                    </p>
                                                    <p>
                                                        <b>
                                                            <span style={{color: 
                                                                results.forces.conditionVal <= 1
                                                                ? 'green' 
                                                                : 'red'
                                                            }}>
                                                                {
                                                                    results.forces.conditionVal <= 1
                                                                    ? 'Ścianka szczelna zaprojektowana poprawnie' 
                                                                    : 'Ścianka szczelna zaprojektowana błędnie'
                                                                }
                                                            </span>
                                                        </b>
                                                    </p>
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :
                                        results.type === 2
                                        ?
                                        <Fragment>
                                            <p>Nośność płyty oporowej</p>
                                            <p>
                                                Masa płyty - {roundNumber(results.plate.m*0.001,3)} kg
                                            </p>
                                            <p>
                                                F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                                {'['}
                                                    -(b<sub>a</sub> - d') + 
                                                    <span style={{ whiteSpace: "nowrap" }}>
                                                        &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;(b<sub>a</sub> - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                                    </span>
                                                {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Fmvrd} kN</b> (
                                                    <span style={{color: results.forces.Fed/results.forces.Fmvrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.Fmvrd,2)}%</span>
                                                )
                                            </p>
                                            <p>
                                                F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;2&nbsp;</span>
                                                </span>
                                                </sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                
                                                {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN</b> (<span style={{color: results.forces.Fed/results.forces.FrdL1 <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.FrdL1,2)}%</span>)
                                            </p>
                                            <p>
                                                F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                </span>
                                                </sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                
                                                
                                                {'=>'} <b>{results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN</b> (
                                                    <span style={{color: results.forces.FrdL1/results.forces.FrdL2 <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.FrdL1/results.forces.FrdL2,2)}%</span>  
                                                )
                                            </p>
                                            <p>
                                                <b>
                                                    <span style={{color: 
                                                        results.forces.FrdL1/results.forces.FrdL2 <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.Fed/results.forces.Fmvrd <= 1
                                                        ? 'green' 
                                                        : 'red'
                                                    }}>
                                                        {
                                                            results.forces.FrdL1/results.forces.FrdL2 <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.Fed/results.forces.Fmvrd <= 1 
                                                            ? 'Płyta oporowa zaprojektowana poprawnie' 
                                                            : 'Płyta oporowa zaprojektowana błędnie'
                                                        }
                                                    </span>
                                                </b>
                                            </p>
                                            <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>
                                            <p>F<sub>Ed,web</sub> &le; 0.5R<sub>c,Rd</sub> {'=>'} <b>{results.forces.Fed*0.5} kN &le; {0.5*results.forces.Rcrd} kN</b> {'=>'} {0.5*results.forces.Fed > 0.5*results.forces.Rcrd ? 'Potrzebna dalsza weryfikacja' : 'Dalsza weryfikacja nie jest konieczna'}</p>
                                            {
                                                0.5*results.forces.Fed <=  0.5*results.forces.Rcrd &&
                                                <p>
                                                    <b>
                                                        <span style={{color:'green'}}>Ścianka szczelna zaprojektowana poprawnie</span>
                                                    </b>
                                                </p>
                                            }
                                        
                                            {
                                                0.5*results.forces.Fed > 0.5*results.forces.Rcrd &&
                                                <Fragment>
                                                    <p>
                                                        <sup>F<sub>Ed</sub></sup>&frasl;<sub>R<sub>c,Rd</sub></sub> + 0.5<sup>M<sub>Ed</sub></sup>&frasl;<sub>M<sub>c,Rd</sub></sub> &le; 1 {'=>'} <b>{results.forces.conditionVal} &le; 1 </b> (<span style={{color: results.forces.conditionVal <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.conditionVal,2)}%</span>)
                                                    </p>
                                                    <p>
                                                        <b>
                                                            <span style={{color: 
                                                                results.forces.conditionVal <= 1
                                                                ? 'green' 
                                                                : 'red'
                                                            }}>
                                                                {
                                                                    results.forces.conditionVal <= 1
                                                                    ? 'Ścianka szczelna zaprojektowana poprawnie' 
                                                                    : 'Ścianka szczelna zaprojektowana błędnie'
                                                                }
                                                            </span>
                                                        </b>
                                                    </p>
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :
                                        results.type === 3
                                        ?
                                        <Fragment>
                                            {
                                                results.sheetPileType === 'u'
                                                ?
                                                <Fragment>
                                                    <p>Nośność płyty oporowej</p>
                                                    <p>
                                                        Masa płyty - {roundNumber(results.plate.m*0.001,3)} kg
                                                    </p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                                            {'['}
                                                                -(b<sub>a</sub> - d') + 
                                                                <span style={{ whiteSpace: "nowrap" }}>
                                                                    &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;(b<sub>a</sub> - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                                                </span>
                                                            {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                    
                                                            {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Fmvrd} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.Fmvrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.Fmvrd,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;2&nbsp;</span>
                                                        </span></sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                        
                                                        {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.FrdL1 <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.FrdL1,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span></sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN</b> (
                                                            <span style={{color: results.forces.FrdL1/results.forces.FrdL2 <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.FrdL1/results.forces.FrdL2,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        <b>
                                                            <span style={{color: 
                                                                results.forces.FrdL1/results.forces.FrdL2 <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.Fed/results.forces.Fmvrd <= 1
                                                                ? 'green' 
                                                                : 'red'
                                                            }}>
                                                                {
                                                                    results.forces.FrdL1/results.forces.FrdL2 <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.Fed/results.forces.Fmvrd <= 1 
                                                                    ? 'Płyta oporowa zaprojektowana poprawnie' 
                                                                    : 'Płyta oporowa zaprojektowana błędnie'
                                                                }
                                                            </span>
                                                        </b>
                                                    </p>

                                                    <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>

                                                    <p>
                                                        F<sub>Ed</sub> &le; R<sub>vf,Rd</sub> = 2(b<sub>b</sub> + h<sub>a</sub>)t<sub>f</sub> <sup>f<sub>y</sub></sup>&frasl;<sub><span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rvfrd} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.Rvfrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.Rvfrd,2)}%</span>
                                                        )
                                                    </p>

                                                    <p>
                                                        F<sub>Ed</sub> &le; R<sub>tw,Rd</sub> = 2h<sub>a</sub>t<sub>w</sub> <sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rtwrd} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.Rtwrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.Rtwrd,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        <b>
                                                            <span style={{color: 
                                                                results.forces.Fed/results.forces.Rtwrd <= 1 && results.forces.Fed/results.forces.Rvfrd <= 1
                                                                ? 'green' 
                                                                : 'red'
                                                            }}>
                                                                {
                                                                    results.forces.Fed/results.forces.Rtwrd <= 1 && results.forces.Fed/results.forces.Rvfrd <= 1
                                                                    ? 'Ścianka szczelna zaprojektowana poprawnie' 
                                                                    : 'Ścianka szczelna zaprojektowana błędnie'
                                                                }
                                                            </span>
                                                        </b>
                                                    </p>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <p>Nośność płyty oporowej</p>
                                                    <p>
                                                        Masa płyty - {roundNumber(results.plate.m*0.001,3)} kg
                                                    </p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                                            {'['}
                                                                -(g - d') + 
                                                                <span style={{ whiteSpace: "nowrap" }}>
                                                                    &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;(g - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                                                </span>
                                                            {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                            {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Fmvrd} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.Fmvrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.Fmvrd,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;2&nbsp;</span>
                                                        </span></sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.FrdL1 <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.FrdL1,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span></sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN</b> (
                                                            <span style={{color: results.forces.FrdL1/results.forces.FrdL2 <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.FrdL1/results.forces.FrdL2,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        <b>
                                                            <span style={{color: 
                                                                results.forces.FrdL1/results.forces.FrdL2 <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.Fed/results.forces.Fmvrd <= 1
                                                                ? 'green' 
                                                                : 'red'
                                                            }}>
                                                                {
                                                                    results.forces.FrdL1/results.forces.FrdL2 <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.Fed/results.forces.Fmvrd <= 1 
                                                                    ? 'Płyta oporowa zaprojektowana poprawnie' 
                                                                    : 'Płyta oporowa zaprojektowana błędnie'
                                                                }
                                                            </span>
                                                        </b>
                                                    </p>
                                                    <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>

                                                    <p>
                                                        F<sub>Ed</sub> &le; R<sub>vf,Rd</sub> = 2(b<sub>b</sub> + h<sub>a</sub>)t<sub>f</sub> <sup>f<sub>y</sub></sup>&frasl;<sub><span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rvfrd} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.Rvfrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.Rvfrd,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; R<sub>tw,Rd</sub> = 2h<sub>a</sub>t<sub>w</sub> <sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rtwrd} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.Rtwrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.Rtwrd,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        <b>
                                                            <span style={{color: 
                                                                results.forces.Fed/results.forces.Rtwrd <= 1 && results.forces.Fed/results.forces.Rvfrd <= 1
                                                                ? 'green' 
                                                                : 'red'
                                                            }}>
                                                                {
                                                                    results.forces.Fed/results.forces.Rtwrd <= 1 && results.forces.Fed/results.forces.Rvfrd <= 1
                                                                    ? 'Ścianka szczelna zaprojektowana poprawnie' 
                                                                    : 'Ścianka szczelna zaprojektowana błędnie'
                                                                }
                                                            </span>
                                                        </b>
                                                    </p>
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :
                                        results.type === 4
                                        ?
                                        <Fragment>
                                            {
                                                results.sheetPileType === 'u'
                                                ?
                                                <Fragment>
                                                    <p>Kleszcz stalowy</p>
                                                    <p>
                                                        M<sub>Ed,waling</sub> = {results.forces.Med} kNm
                                                    </p>
                                                    <p>
                                                        W<sub>x,req</sub> = {results.waling.ceoWx} cm<sup>3</sup>
                                                    </p>
                                                    <p>
                                                        W<sub>x,req</sub> &le; W<sub>x,ceo</sub>

                                                        {'=>'} <b>{results.waling.ceoWx} cm<sup>3</sup> &le; {results.waling.designCeo[1].wx} cm<sup>3</sup></b> (
                                                            <span style={{color: results.waling.ceoWx/results.waling.designCeo[1].wx <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.waling.ceoWx/results.waling.designCeo[1].wx,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        <b>
                                                            <span style={{color: results.waling.ceoWx/results.waling.designCeo[1].wx <= 1 ? 'green' : 'red'}}>
                                                                {
                                                                    results.waling.ceoWx/results.waling.designCeo[1].wx <= 1 
                                                                    ? 
                                                                    'Kleszcz zaprojektowany poprawnie' 
                                                                    : 
                                                                    'Kleszcz zaprojektowany błędnie'
                                                                }
                                                            </span>
                                                        </b>
                                                    </p>
                                                    <p>Nośność płyty oporowej</p>
                                                    <p>
                                                        Masa płyty - {roundNumber(results.plate.m*0.001,3)} kg
                                                    </p>
                                                    <p>
                                                        F<sub>P,Ed</sub> &le; F<sub>P,M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                                        {'['}
                                                            -(b<sub>a</sub> - d') + 
                                                            <span style={{ whiteSpace: "nowrap" }}>
                                                                &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;(b<sub>a</sub> - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                                            </span>
                                                        {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                        {'=>'} <b>{results.forces.Fp} kN &le; {results.forces.Fpmvrd} kN</b> (
                                                            <span style={{color: results.forces.Fp/results.forces.Fpmvrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fp/results.forces.Fpmvrd,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        F<sub>W,Ed</sub> &le; F<sub>W,M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                                        {'['}
                                                            -(s - d') + 
                                                            <span style={{ whiteSpace: "nowrap" }}>
                                                                &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;(s - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                                            </span>
                                                        {']'} (b<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                        {'=>'} <b>{results.forces.Fw} kN &le; {results.forces.Fwmvrd} kN</b> (
                                                            <span style={{color: results.forces.Fw/results.forces.Fwmvrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fw/results.forces.Fwmvrd,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;2&nbsp;</span>
                                                        </span></sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.FrdL1 <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.FrdL1,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span></sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN</b> (
                                                            <span style={{color: results.forces.FrdL1/results.forces.FrdL2 <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.FrdL1/results.forces.FrdL2,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        <b>
                                                            <span style={{color: 
                                                                results.forces.Fp/results.forces.Fpmvrd <= 1 && results.forces.Fw/results.forces.Fwmvrd <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.FrdL1/results.forces.FrdL2
                                                                ? 'green' 
                                                                : 'red'
                                                            }}>
                                                                {
                                                                    results.forces.Fp/results.forces.Fpmvrd <= 1 && results.forces.Fw/results.forces.Fwmvrd <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.FrdL1/results.forces.FrdL2
                                                                    ? 'Płyta oporowa zaprojektowana poprawnie' 
                                                                    : 'Płyta oporowa zaprojektowana błędnie'
                                                                }
                                                            </span>
                                                        </b>
                                                    </p>
                                                    <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; R<sub>vf,Rd</sub> = 2(b<sub>b</sub> + h<sub>a</sub>)t<sub>f</sub> <sup>f<sub>y</sub></sup>&frasl;<sub><span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rvfrd} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.Rvfrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.Rvfrd,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; R<sub>tw,Rd</sub> = 2h<sub>a</sub>t<sub>w</sub> <sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rtwrd} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.Rtwrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.Rtwrd,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        <b>
                                                            <span style={{color: 
                                                                results.forces.Fed/results.forces.Rtwrd <= 1 && results.forces.Fed/results.forces.Rvfrd <= 1
                                                                ? 'green' 
                                                                : 'red'
                                                            }}>
                                                                {
                                                                    results.forces.Fed/results.forces.Rtwrd <= 1 && results.forces.Fed/results.forces.Rvfrd <= 1
                                                                    ? 'Ścianka szczelna zaprojektowana poprawnie' 
                                                                    : 'Ścianka szczelna zaprojektowana błędnie'
                                                                }
                                                            </span>
                                                        </b>
                                                    </p>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <p>Kleszcz stalowy</p>
                                                    <p>
                                                        M<sub>Ed,waling</sub> = {results.forces.Med} kNm
                                                    </p>
                                                    <p>
                                                        W<sub>x,req</sub> = {results.waling.ceoWx} cm<sup>3</sup>
                                                    </p>
                                                    <p>
                                                        W<sub>x,req</sub> &le; W<sub>x,ceo</sub>

                                                        {'=>'} <b>{results.waling.ceoWx} cm<sup>3</sup> &le; {results.waling.designCeo[1].wx} cm<sup>3</sup></b> (
                                                            <span style={{color: results.waling.ceoWx/results.waling.designCeo[1].wx <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.waling.ceoWx/results.waling.designCeo[1].wx,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        <b>
                                                            <span style={{color: results.waling.ceoWx/results.waling.designCeo[1].wx <= 1 ? 'green' : 'red'}}>
                                                                {
                                                                    results.waling.ceoWx/results.waling.designCeo[1].wx <= 1 
                                                                    ? 
                                                                    'Kleszcz zaprojektowany poprawnie' 
                                                                    : 
                                                                    'Kleszcz zaprojektowany błędnie'
                                                                }
                                                            </span>
                                                        </b>
                                                    </p>
                                                    <p>Nośność płyty oporowej</p>
                                                    <p>
                                                        Masa płyty - {roundNumber(results.plate.m*0.001,3)} kg
                                                    </p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                                        {'['}
                                                            -(g - d') + 
                                                            <span style={{ whiteSpace: "nowrap" }}>
                                                                &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;(g - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                                            </span>
                                                        {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                        {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Fmvrd} kN</b> (
                                                        <span style={{color: results.forces.Fed/results.forces.Fmvrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.Fmvrd,2)}%</span>)
                                                    </p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;2&nbsp;</span>
                                                        </span></sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.FrdL1 <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.FrdL1,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span></sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN</b> (
                                                            <span style={{color: results.forces.FrdL1/results.forces.FrdL2 <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.FrdL1/results.forces.FrdL2,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        M<sub>Ed</sub> = 0.25&middot;F<sub>W,Ed</sub>(s-d') = {roundNumber(results.forces.Medplate*0.01,2)} kNm
                                                    </p>
                                                    <p>
                                                        M<sub>Ed</sub> &le; M<sub>pl,Rd</sub> =
                                                        {'['}
                                                        <sup>1</sup>&frasl;<sub>4</sub>(b<sub>a</sub> - d)t<sub>a</sub><sup>2</sup> +
                                                        
                                                        c&middot;e(t<sub>a</sub> - <sup>c&middot;e</sup>&frasl;<sub>(b<sub>a</sub> - d)</sub> + c){']'}<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                        {'=>'} <b>{roundNumber(results.forces.Medplate*0.01,2)} kNm &le; {roundNumber(results.forces.MplRd*0.01,2)} kNm</b> (
                                                            <span style={{color: results.forces.Medplate/results.forces.MplRd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Medplate/results.forces.MplRd,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        V<sub>Ed</sub> = 0.5&middot;F<sub>W,Ed</sub> = {results.forces.Vedplate} kN
                                                    </p>
                                                    <p>
                                                        V<sub>Ed</sub> &le; 0.5&middot;V<sub>pl,Rd</sub> =
                                                        {' ['}
                                                        (b<sub>a</sub> - d)t<sub>a</sub> +
                                                        
                                                        2c&middot;e{']'}<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                        {'=>'} <b>{results.forces.Vedplate} kN &le; {results.forces.VplRd} kN</b> (
                                                            <span style={{color: results.forces.Vedplate/results.forces.VplRd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Vedplate/results.forces.VplRd,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        <b>
                                                            <span style={{color: 
                                                                results.forces.Fed/results.forces.Fmvrd <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.FrdL1/results.forces.FrdL2 && results.forces.Medplate/results.forces.MplRd <= 1 && results.forces.Vedplate/results.forces.VplRd <= 1
                                                                ? 'green' 
                                                                : 'red'
                                                            }}>
                                                                {
                                                                    results.forces.Fed/results.forces.Fmvrd <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.FrdL1/results.forces.FrdL2 && results.forces.Medplate/results.forces.MplRd <= 1 && results.forces.Vedplate/results.forces.VplRd <= 1
                                                                    ? 'Płyta oporowa zaprojektowana poprawnie' 
                                                                    : 'Płyta oporowa zaprojektowana błędnie'
                                                                }
                                                            </span>
                                                        </b>
                                                    </p>
                                                    <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; R<sub>vf,Rd</sub> = 2(b<sub>b</sub> + h<sub>a</sub>)t<sub>f</sub> <sup>f<sub>y</sub></sup>&frasl;<sub><span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rvfrd} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.Rvfrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.Rvfrd,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; R<sub>tw,Rd</sub> = 2h<sub>a</sub>t<sub>w</sub> <sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                        {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rtwrd} kN</b> (
                                                            <span style={{color: results.forces.Fed/results.forces.Rtwrd <= 1 ? 'green' : 'red'}}>{roundNumber(100*results.forces.Fed/results.forces.Rtwrd,2)}%</span>
                                                        )
                                                    </p>
                                                    <p>
                                                        <b>
                                                            <span style={{color: 
                                                                results.forces.Fed/results.forces.Rtwrd <= 1 && results.forces.Fed/results.forces.Rvfrd <= 1
                                                                ? 'green' 
                                                                : 'red'
                                                            }}>
                                                                {
                                                                    results.forces.Fed/results.forces.Rtwrd <= 1 && results.forces.Fed/results.forces.Rvfrd <= 1
                                                                    ? 'Ścianka szczelna zaprojektowana poprawnie' 
                                                                    : 'Ścianka szczelna zaprojektowana błędnie'
                                                                }
                                                            </span>
                                                        </b>
                                                    </p>
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :
                                        <Fragment></Fragment>
                                    }
                                </Col>
                            </Row>
                            :
                            <Row>
                                {
                                    (results.type === 1 || results.type === 4) &&

                                    isWaling ?
                                    <Col xs={12} md={6}>
                                        <SectionTitle title="Kleszcz stalowy" />
                                        <p>Rodzaj kleszcza: <span className={styles.value}><b>2x{results.waling.designCeo[0]}</b></span></p>
                                        <p>Wskaźnik wytrzymałości [W<sub>x</sub>]: <span className={styles.value}>{results.waling.ceoWx} cm<sup>3</sup></span></p>
                                        <p>Rozstaw kleszczy [s]: <span className={styles.value}>{results.waling.s} cm</span></p>
                                        <p>Granica plastyczności stali [f<sub>yk</sub>]: <span className={styles.value}>{results.waling.walingFy} MPa</span></p>
                                    </Col>
                                    :
                                    <Col xs={12} md={6}>
                                        <SectionTitle title="Kleszcz stalowy" />
                                        <p>Brak odpowiedniego kleszcza w bazie danych.</p>
                                        <p>Wymagany wskaźnik wytrzymałości [W<sub>x</sub>]: <span className={styles.value}>{results.waling.ceoWx} cm<sup>3</sup></span></p>
                                    </Col>
                                }
                                {
                                    results.plate &&
                                    <Col xs={12} md={6}>
                                        <SectionTitle title="Płyta oporowa" />
                                        <p>Szerokość [b<sub>w</sub>]: <span className={styles.value}>{roundNumber(results.plate.ba*10,2)} mm</span></p>
                                        <p>Wysokość [h<sub>p</sub>]: <span className={styles.value}>{roundNumber(results.plate.ha*10,2)} mm</span></p>
                                        <p>Grubość [t<sub>p</sub>]: <span className={styles.value}>{roundNumber(results.plate.ta*10,2)} mm</span></p>
                                        <p>
                                            Masa płyty - {roundNumber(results.plate.m*0.001,3)} kg
                                        </p>
                                        {
                                            results.plate.c &&
                                            <p>Wysokość podkładki [c]: <span className={styles.value}>{results.plate.c*10} mm</span></p>
                                        }
                                        {
                                            results.plate.e &&
                                            <p>Szerokość podkładki [e]: <span className={styles.value}>{results.plate.e*10} mm</span></p>
                                        }
                                        <p>Granica plastyczności stali [f<sub>yk</sub>]: <span className={styles.value}>{results.plate.fy*10} MPa</span></p>
                                    </Col>
                                }
                                <Col xs={12}>
                                    <SectionTitle title="Warunki wytrzymałości" />
                                    {
                                        (results.type === 1 || results.type === 2)
                                        ?
                                        <Fragment>
                                            <p>Nośność płyty oporowej</p>
                                            <p>
                                                F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                                    {'['}
                                                        -(b<sub>a</sub> - d') + 
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;(b<sub>a</sub> - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                                        </span>
                                                    {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                            
                                            {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Fmvrd} kN</b></p>

                                            <p>F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;2&nbsp;</span>
                                                </span>
                                            </sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                            
                                            {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN</b></p>

                                            <p>F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                </span>
                                            </sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                            
                                            
                                            {'=>'} <b>{results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN</b></p>

                                            <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>
                                            <p>F<sub>Ed,web</sub> &le; 0.5R<sub>c,Rd</sub> {'=>'} <b>{results.forces.Fed*0.5} kN &le; {0.5*results.forces.Rcrd} kN</b> {'=>'} {0.5*results.forces.Fed > 0.5*results.forces.Rcrd ? 'Potrzebna dalsza weryfikacja' : 'Dalsza weryfikacja nie jest konieczna'}</p>
                                        
                                            {
                                                0.5*results.forces.Fed > 0.5*results.forces.Rcrd &&
                                                <Fragment>
                                                    <p>
                                                        <sup>F<sub>Ed</sub></sup>&frasl;<sub>R<sub>c,Rd</sub></sub> + 0.5<sup>M<sub>Ed</sub></sup>&frasl;<sub>M<sub>c,Rd</sub></sub> &le; 1 {'=>'} <b>{roundNumber(results.forces.conditionVal,2)} &le; 1</b>

                                                        {' =>'} {
                                                            roundNumber(results.forces.conditionVal,2) <=  1 ? 'Warunek spełniony' : <b>Warunek nośność ścianki szczelnej nie jest spełniony</b>
                                                        }
                                                    </p>
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :
                                        results.type === 3
                                        ?
                                        <Fragment>
                                            {
                                                results.sheetPileType === 'u'
                                                ?
                                                <Fragment>
                                                    <p>Nośność płyty oporowej</p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                                            {'['}
                                                                -(b<sub>a</sub> - d') + 
                                                                <span style={{ whiteSpace: "nowrap" }}>
                                                                    &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;(b<sub>a</sub> - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                                                </span>
                                                            {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                    
                                                    {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Fmvrd} kN</b></p>

                                                    <p>F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;2&nbsp;</span>
                                                        </span>
                                                    </sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN</b></p>

                                                    <p>F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span>
                                                    </sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                    
                                                    {'=>'} <b>{results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN</b></p>

                                                    <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>

                                                    <p>F<sub>Ed</sub> &le; R<sub>vf,Rd</sub> = 2(b<sub>b</sub> + h<sub>a</sub>)t<sub>f</sub> <sup>f<sub>y</sub></sup>&frasl;<sub><span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rvfrd} kN</b></p>

                                                    <p>F<sub>Ed</sub> &le; R<sub>tw,Rd</sub> = 2h<sub>a</sub>t<sub>w</sub> <sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rtwrd} kN</b></p>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <p>Nośność płyty oporowej</p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                                            {'['}
                                                                -(g - d') + 
                                                                <span style={{ whiteSpace: "nowrap" }}>
                                                                    &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;(g - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                                                </span>
                                                            {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                            {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Fmvrd} kN</b>
                                                    </p>

                                                    <p>F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;2&nbsp;</span>
                                                        </span>
                                                    </sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN</b></p>

                                                    <p>F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span>
                                                    </sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN</b></p>

                                                    <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>

                                                    <p>F<sub>Ed</sub> &le; R<sub>vf,Rd</sub> = 2(b<sub>b</sub> + h<sub>a</sub>)t<sub>f</sub> <sup>f<sub>y</sub></sup>&frasl;<sub><span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rvfrd} kN</b></p>

                                                    <p>F<sub>Ed</sub> &le; R<sub>tw,Rd</sub> = 2h<sub>a</sub>t<sub>w</sub> <sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rtwrd} kN</b></p>
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :
                                        results.type === 4
                                        ?
                                        <Fragment>
                                            {
                                                results.sheetPileType === 'u'
                                                ?
                                                <Fragment>
                                                    <p>Nośność płyty oporowej</p>
                                                    <p>
                                                        F<sub>P,Ed</sub> &le; F<sub>P,M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                                            {'['}
                                                                -(b<sub>a</sub> - d') + 
                                                                <span style={{ whiteSpace: "nowrap" }}>
                                                                    &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;(b<sub>a</sub> - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                                                </span>
                                                            {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                    
                                                    {'=>'} <b>{results.forces.Fp} kN &le; {results.forces.Fpmvrd} kN</b></p>

                                                    <p>
                                                        F<sub>W,Ed</sub> &le; F<sub>W,M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                                            {'['}
                                                                -(s - d') + 
                                                                <span style={{ whiteSpace: "nowrap" }}>
                                                                    &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;(s - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                                                </span>
                                                            {']'} (b<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                        {'=>'} <b>{results.forces.Fw} kN &le; {results.forces.Fwmvrd} kN</b>
                                                    </p>

                                                    <p>F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;2&nbsp;</span>
                                                        </span>
                                                    </sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN</b></p>

                                                    <p>F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span>
                                                    </sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                    
                                                    {'=>'} <b>{results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN</b></p>

                                                    <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>

                                                    <p>F<sub>Ed</sub> &le; R<sub>vf,Rd</sub> = 2(b<sub>b</sub> + h<sub>a</sub>)t<sub>f</sub> <sup>f<sub>y</sub></sup>&frasl;<sub><span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rvfrd} kN</b></p>

                                                    <p>F<sub>Ed</sub> &le; R<sub>tw,Rd</sub> = 2h<sub>a</sub>t<sub>w</sub> <sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rtwrd} kN</b></p>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <p>Nośność płyty oporowej</p>
                                                    <p>
                                                        F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                                            {'['}
                                                                -(g - d') + 
                                                                <span style={{ whiteSpace: "nowrap" }}>
                                                                    &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;(g - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                                                </span>
                                                            {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                                            {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Fmvrd} kN</b>
                                                    </p>

                                                    <p>F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;2&nbsp;</span>
                                                        </span>
                                                    </sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN</b></p>

                                                    <p>F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span>
                                                    </sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN</b></p>

                                                    <p>
                                                        M<sub>Ed</sub> = 0.25&middot;F<sub>W,Ed</sub>(s-d') = {roundNumber(results.forces.Medplate*0.01,2)} kNm
                                                    </p>
                                                    
                                                    <p>
                                                        M<sub>Ed</sub> &le; M<sub>pl,Rd</sub> =
                                                            {'['}
                                                                <sup>1</sup>&frasl;<sub>4</sub>(b<sub>a</sub> - d)t<sub>a</sub><sup>2</sup> +
                                                                
                                                                c&middot;e(t<sub>a</sub> - <sup>c&middot;e</sup>&frasl;<sub>(b<sub>a</sub> - d)</sub> + c){']'}<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                            {'=>'} <b>{roundNumber(results.forces.Medplate*0.01,2)} kNm &le; {roundNumber(results.forces.MplRd*0.01,2)} kNm</b>
                                                    </p>

                                                    <p>
                                                        V<sub>Ed</sub> = 0.5&middot;F<sub>W,Ed</sub> = {results.forces.Vedplate} kN
                                                    </p>
                                                    
                                                    <p>
                                                        V<sub>Ed</sub> &le; 0.5&middot;V<sub>pl,Rd</sub> =
                                                            {' ['}
                                                                (b<sub>a</sub> - d)t<sub>a</sub> +
                                                                
                                                                2c&middot;e{']'}<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                            {'=>'} <b>{results.forces.Vedplate} kN &le; {results.forces.VplRd} kN</b>
                                                    </p>

                                                    <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>

                                                    <p>F<sub>Ed</sub> &le; R<sub>vf,Rd</sub> = 2(b<sub>b</sub> + h<sub>a</sub>)t<sub>f</sub> <sup>f<sub>y</sub></sup>&frasl;<sub><span style={{ whiteSpace: "nowrap" }}>
                                                            &radic;<span style={{ borderTop: "1px solid black" }}>&nbsp;3&nbsp;</span>
                                                        </span>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rvfrd} kN</b></p>

                                                    <p>F<sub>Ed</sub> &le; R<sub>tw,Rd</sub> = 2h<sub>a</sub>t<sub>w</sub> <sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                                    
                                                    {'=>'} <b>{results.forces.Fed} kN &le; {results.forces.Rtwrd} kN</b></p>
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :
                                        <Fragment></Fragment>
                                    }

                                </Col>
                            </Row>                            
                        }
                    </Fragment>
                    :
                    <h2>Wymiarowanie elementów nie jest możliwe, należy zmienić średnicę otworu/nakrętki.</h2>
                }
            </div>
        </Fragment>
    );
};

export default Results;
